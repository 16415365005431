var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-3",
    class: {
      'bg-secondary section-with-bg': _vm.hasBackground,
      'section-logos': _vm.hasInnerBackground
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', [_vm.onlyTitle ? _c('h2', {
    staticClass: "best-picks"
  }, [_vm._v(_vm._s(_vm.title))]) : _vm._e()]), _c('div', {
    staticClass: "d-flex justify-content-between section-slider-head"
  }, [_vm.showTitle ? _c('h3', {
    staticClass: "font-weight-normal"
  }, [_vm._v(_vm._s(_vm.subTitle))]) : _vm._e(), _c('router-link', {
    staticStyle: {
      "text-decoration": "none",
      "color": "inherit"
    },
    attrs: {
      "to": _vm.viewMorePage
    }
  }, [_vm.hasButton ? _c('button', [_vm._v("View more")]) : _vm._e()])], 1), _vm.withSlider ? _c('v-slide-group', {
    ref: "slideGroup",
    attrs: {
      "show-arrows": "always"
    }
  }, _vm._l(_vm.list, function (data, i) {
    return _c('v-slide-item', {
      key: i
    }, [_vm._t("default", null, {
      "listItem": data
    })], 2);
  }), 1) : _vm._e(), _vm.withoutSlider ? _c('v-slide-item', [_c('b-row', _vm._l(_vm.list, function (data, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "md": "3"
      }
    }, [_vm._t("default", null, {
      "listItem": data
    })], 2);
  }), 1)], 1) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }