<template>
  <div
    class="mt-3"
    v-bind:class="{
      'bg-secondary section-with-bg': hasBackground,
      'section-logos': hasInnerBackground,
    }"
  >
    <div class="container">
      <div>
        <h2 v-if="onlyTitle" class="best-picks">{{ title }}</h2>
      </div>
      <div class="d-flex justify-content-between section-slider-head">
        <h3 class="font-weight-normal" v-if="showTitle">{{ subTitle }}</h3>
        <router-link
          style="text-decoration: none; color: inherit"
          :to="viewMorePage"
        >
          <button v-if="hasButton">View more</button>
        </router-link>
      </div>

      <v-slide-group v-if="withSlider" ref="slideGroup" show-arrows="always">
        <v-slide-item v-for="(data, i) in list" :key="i">
          <slot :listItem="data"></slot>
        </v-slide-item>
      </v-slide-group>

      <v-slide-item v-if="withoutSlider">
        <b-row>
          <b-col md="3" v-for="(data, i) in list" :key="i">
            <slot :listItem="data"></slot>
          </b-col>
        </b-row>
      </v-slide-item>
    </div>
  </div>
</template>

<script>
export default {
  updated() {
    this.$refs.slideGroup.setWidths();
  },
  props: {
    showTitle: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Best Picks",
    },
    onlyTitle: {
      type: Boolean,
      default: false,
    },
    subTitle: {
      type: String,
      default: "Default Title",
    },
    viewMorePage: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
    hasInnerBackground: {
      type: Boolean,
      default: false,
    },
    hasButton: {
      type: Boolean,
      default: false,
    },
    withSlider: {
      type: Boolean,
      default: false,
    },
    withoutSlider: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.section-with-bg {
  padding-top: 10px;
  padding-bottom: 20px;
}

.section-logos {
  height: 215px;
}

.section-home-service {
  height: 413px;
  margin-bottom: 34px;
}

.section-logos .v-slide-group {
  background-color: var(--secondary);
  height: 160px;
  padding: 21px 27px 21px 27px;
  margin-top: -40px;
}

.section-slider {
  height: 190px;
  z-index: 15;
}

.section-slider-head {
  flex: 0 1 auto;
  position: relative;
  display: flex;
  margin-bottom: 36px;
  align-items: center;
}

.section-slider-head button {
  color: #fe7b08;
  width: 140px;
  font-size: 16px;
  height: 45px;
  border: 1px solid #fe7b08;
  border-radius: 3px;
}

.section-slider-head button:hover {
  color: white;
  background-color: #fe7b08;
}

.section-slider h1 {
  font-size: 26px;
  margin-bottom: 25px;
}

.section-slider > .v-slide-group {
  background-color: var(--secondary);
}

@media (max-width: 768px) {
  .section-slider-head button {
    width: auto;
    height: auto;
    border: none;
  }
  .section-slider-head button:hover {
    color: #fe7b08;
    background-color: transparent;
  }
  .section-slider-head h1 {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 0;
  }
  .section-slider-head {
    margin-bottom: 12px;
  }
  .section-slider-head h3 {
    font-size: 1.3rem;
  }
  .section-logos .v-slide-group {
    margin-top: 0px;
    height: 120px;
    padding: 11px 17px 11px 17px;
  }
  .section-logos {
    height: 190px;
  }
}
</style>
